import { Link } from 'gatsby';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { AppButton } from '../../components/core/AppButton';
import AppInput from '../../components/core/AppInput/AppInput';
import { AuthApi } from '../../services/api/Auth';
import { checkEmail } from '../../utils/validation';

interface Props {}

const Reset = (props: Props) => {
	const [isSended, setIsSended] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [error, setError] = useState(false);

	const handleSendEmail = () => {
		setLoading(true);

		AuthApi.resetPassword({ email })
			.then(() => {
				setIsSended(true);
			})
			.catch((error) => {
				setError(error?.description);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<Helmet>
				<title>Reset password</title>
			</Helmet>
			<div className="centered" style={{ gap: 16 }}>
				{isSended ? (
					<div className="centered container">
						<div className="max-w-xs mb-4 text-center">
							<p>We've send new password to the email address you have supplied.</p>
							<b className="underline decoration-slate-500">{email}</b>
							<p>If you don't receive an email within 5 minutes please check spam folder.</p>
						</div>
						<p></p>
						<Link to="/login">
							<AppButton>Back to log in</AppButton>
						</Link>
					</div>
				) : (
					<form method="POST">
						<div className="flex flex-col gap-4">
							<h1 className="text-4xl">Reset password</h1>
							<AppInput
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								type="email"
								placeholder="Email"
								error={error}
							></AppInput>

							<Link className="text-center" to="/login">
								Back to Login
							</Link>
							<AppButton disabled={!checkEmail(email) || loading} onClick={handleSendEmail}>
								Send me mail
							</AppButton>
						</div>
					</form>
				)}
			</div>
		</>
	);
};

export default Reset;
